.loginContainer{
    color: #2D3136;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 28px;
    font-weight: 300;
    align-self: center;
    align-items: center;
    height: 60%;
    padding-left: 0%;
    margin-left: -17%
  }

  .loginContainer .formContainer {
    width: 40%;
  }

.email-address-input-header {
    color: #000;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    padding-top: 3%;
}

#standard-basic {
  width: 150%;
}

.login-credentials-in {
  
    color: #B21313;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 17px;
}

li {
  list-style-type: none;
}

.logo {
  height: 60px;
  width: 60px;
}

.login-button {
  color: "#FFF";
  background-color: #3B7D7D; 
  width: '29.5%';
  margin-top: 10px;
}