.items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}

.items > .item {
    width: calc(50% - 2em);
    height: 210px;
    width: 49%;
    margin: 0.1rem;
    background: #3B7D7D;
}

.items > .picture {
    width: calc(50% - 2em);
    height: 210px;
    width: 49%;
    margin: 0.1rem;
}

.items > .itemToDelete {
    width: calc(50% - 2em);
    height: 210px;
    width: 49%;
    margin: 0.1rem;
    background: #000;
}

.selectClinicNameLine { 
    position: relative;
    right: 4px;
    width: 24.5rem;
    height: 1.6px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}