html, body {
    height: 100%;
}

h1 {
    color: #2D3136;
    font-family: "sans-serif";
    font-size: 28px;
    letter-spacing: 0;
    line-height: 35px;
}

.links {
    color: #AEBFBF;
    text-decoration: none;
}

.navbar {
    background-color: #3B7D7D;
}

.navbar ul {
    margin-left: 20%;
}

.navbar li {
    position: relative;
    margin-left: 1.6%;
    justify-content: center;
    padding-bottom: 2%;
    z-index: 2;
    line-height: 4rem;
    font-size: 1.4rem;
}

.navbar .logo {
    height: 60px;
    width: 60px;
    align-self: center;
    margin-top: 5%;
    margin-left: 10%;
    margin-bottom: 20px; 
}

main {
    background-color: #F7F7F7;
}

.container-fluid {
    background-color: #F7F7F7;
}

/*******************************
        RESILIANCY
********************************/

.resiliancyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px #bababa;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.resiliancyDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 66%;
    border-bottom: solid 2px #bababa;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.resiliancy-table {
}

.resiliancy-table table {
    border-collapse: separate;
    border-spacing:0 8px;
    width: 100%;
}

.resiliancy-table tr {
    background-color: white;
    height: 4rem;
}

.resiliancy-table table td {
    vertical-align: middle;
}

.resiliancy-table thead tr {
    border-bottom: 2px solid  #bababa;
    background-color: #f7f7f7;
}

.resiliancy-table thead tr th {
    font-weight: normal;
}