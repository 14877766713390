

.tribe-label {
    font-size: 14pt;
    align-self: center;
    font-weight: bold;
    color: #000;
}

.button { 
    position: relative;
    left: 81rem;
    bottom: 30.2rem;
    color: #FFF;
    background-color: #3B7D7D;
    font-size: 12pt;
    width: 80px;
    border: 10px solid  #3B7D7D;
    background-color: #3B7D7D;
}

.add-tribe-button, .delete-tribe-button {
    position: relative;
    align-self: center;
    text-align: center;
    justify-content: center;
    color: #FFF;
    background-color: #3B7D7D;
    height: 36px;
    border: 5px solid  #3B7D7D;
    background-color: #3B7D7D;
}

.save-tribe-button {
    position: relative;
    align-self: center;
    text-align: center;
    justify-content: center;
    top: 2rem;
    color: #FFF;
    background-color: #3B7D7D;
    width: 340px;
    border: 5px solid  #3B7D7D;
    background-color: #3B7D7D;
    left: 2.3rem;
    height: 30px;
}

.delete-tribe-button {
    color: #000;
    border: 1.2px solid  #3B7D7D;
    background-color: #FFF;
    height: 30px;
}

.culture-boxes {
    flex-direction: row;
    height: 2.7rem;
    width: 67.4rem;
    margin-right: 10px;
    margin-left: 10px;
    border: 5px solid  rgb(229, 231, 231);
    background-color: rgb(229, 231, 231);
    display: flex;
    text-align: center;
    position: relative;
    flex-direction: column;
    margin-bottom: 1rem;
}

.titles {
    width: 280px;
    left: 1rem;
    position: relative;
    flex-direction: row;
    float: right;
    top: 0.8rem;
    text-align: left;
}

.pencils {
    align-self: flex-end;
    right: 0.9rem;
    position: relative;
    background-color: rgb(229, 231, 231);
    border: 0px solid rgb(229, 231, 231);
    bottom: 0.8rem;
}