.entire-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard {
    background-color: #3B7D7D;
    position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
    height: 100%;
    width: 15%;    
    z-index: -1;  /* Stay on top of everything */
    top: 0;      /* Stay at the top */
    left: 0;    /* Say at on the left side */
    overflow-x: hidden;     /* Disable horizontal scroll */
    align-items: flex-start;
}

.boxes, .survery-boxes {
    position: relative;
}

.assessments-button {
    height: 130px;
    border: 15px solid  #3B7D7D;
    background-color: #3B7D7D;
}

.assessments-button-one {
    left: 0.8rem;
    position: relative;
    width: 120px;
    z-index: 2;
    border: 15px solid  #3B7D7D;
    background-color: #3B7D7D;
}
.assessments-button-two {
    left: 2rem;;
    position: relative;
    z-index: 2;
    width: 120px;
    border: 15px solid  #3B7D7D;
    background-color: #3B7D7D;
}

#ass-btn-1, #ass-btn-2 {
    text-align: center;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}

.date-picker-one {
    position: relative;
}
.date-picker-two {
    position: relative;
}

.update, .buttonload {
    padding-left: 16px;
    position: relative;
    background-color: #3B7D7D;
    height: 30px;
    width: 95px;
    border: 1px solid #3B7D7D;
    font-size: 12.5px;
    font-weight: 700;
    margin-right: 10px;
    color: #FFF
}
.button-text {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.general-header, .events-header, .resources-header, .surveys-header  {
    position: relative;
    color: #000000;
    font-family: "Open Sans Regular";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    bottom: 7rem;
}

.events-header {
    bottom: 4.6rem;
}

hr.header-line {
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.general-header-line{
    position: relative;
    height: 2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.events-header-line {
    bottom: 5.2rem;
    position: relative;
    height: 2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.resources-header-line {
    right: 1.45rem;
    bottom: 8.72rem;
    position: relative;
    height:2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.geographic-header-line {
    right: 8.8rem;
    bottom: 17rem;
    position: relative;
    width: 55rem;
    height:2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.resources-header {
    bottom: 7.7rem;
    left: 54.2rem;
}

.geographic-header {
    bottom: 270px; 
    left: 18.9rem;
    position: relative;
}

.general-assessments, .survery-scores {
    margin: 16px;
    height: 130px;
    background-color: #FFF;
    display: flex;
    text-align: center;
    position: relative;
}

.obj-number {
    color: #000000;
    font-family: "Open Sans";
    letter-spacing: 0;
    position: relative;
}

.obj-number-two {
    top: 10px;
    position: relative;
}

.obj-text, .obj-text-two {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    align-self: center;
    bottom: 5px;
    position: relative;
}

.obj-text-two {
    bottom: 20px;
    position: relative;
}

.scrollist-three {
    bottom: 17.47rem;
    left: 3.2rem;
    position: relative;
}

.boxs {
    position: relative;
    /* left: 15.7rem; */
    background-color: rgb(229, 231, 231);
    /* flex-direction: row; */
    /* width: 33.5rem; */
    /* margin-bottom: -0.8rem; */
}

.obj-title {
    position: relative;
    font-size: 15px;
    /* font-family: "Open Sans"; */
}

.obj-value {
    position: relative;
    font-size: 15px;
    /* font-family: "Open Sans"; */
}

.surveys-header {
    bottom: 23.2rem;
}

.surveys-header-line {
    bottom: 23.2rem;
    position: relative;
    width: 55rem;
    left: 1.7rem;
    height:2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.surveys {
    position: relative;
}

.survey-range {
    position: relative;
    font-size: 11px;
}

.survey-titles {
    height: 42px;
    border-radius: 45px;
    border: 1px solid;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
}

.survey-titles:focus {
    outline:0;
}

.survery-scores {
    position: relative;
    font-weight: bold;
}