.assessmentHeaderLine { 
    position: relative;
    width: 68.7rem;
    right: 10.15rem;
    height:2px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.addButton { 
    position: relative;
    left: 63.6rem;
    bottom: 3.5rem;
    color: #FFF;
    background-color: #3B7D7D;
    font-size: 12pt;
    width: 80px;
    border: 10px solid  #3B7D7D;
    background-color: #3B7D7D;
}

.selectConditionNameLine { 
    top: 1.3rem;
    position: relative;
    right: 4.5px;
    width: 21.4rem;
    height: 1.6px;
    border-width:0;
    color:#C2C2C2;
    background-color:#C2C2C2;
}

.range {
    position: relative;
    left: 2rem;
}