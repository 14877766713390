.scrolllist-one, .scrolllist-two {
    bottom: 2.47rem;
    position: relative;
}

.scrolllist-three {
    bottom: 17.47rem;
    right: 0.6rem;
    position: relative;
}

.scrolllist-two {
    bottom: 0.2rem;
    left: 12rem;
}

.boxst {
    position: relative;
    right: 10.7rem;
    background-color:rgb(229, 231, 231);
    width: 35.7rem;
    height: 3.3rem;
    margin-bottom: -0.8rem;
    z-index: -1;
}

.words {
    position: relative;
   font-size: 12pt;
   color: #000;
   z-index: 3;
}

.upload-audio-button, .remove-audio-button {
    position: relative;
    text-align: center;
    color: #000;
    background-color: #FFF;
    width: 340px;
    height: 2.4rem;
    border: 1px solid  #3B7D7D;
}

.remove-audio-button {
    bottom: 11rem;
}

.add-word-button, .delete-word-button {
    text-align: center;
    justify-content: center;
    color: #FFF;
    font-weight: bold;
    background-color: #3B7D7D;
    width: 340px;
    height: 2.4rem;
    border: 1px solid  #3B7D7D;
}

.delete-word-button {
    margin-top: 0.3rem;
    border: 1px solid  #3B7D7D;
    background-color: #FFF;
    color: #000;
}