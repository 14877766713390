
.admin-table table {
    border-collapse: separate;
    border-spacing:0 20px;
}

.admin-table tr {
    background-color: white;
    height: 4rem;
}

.admin-table table td {
    vertical-align: middle;
}

.admin-table thead tr {
    border-bottom: 2px solid  #bababa;
    background-color: #f7f7f7;
}

.admin-table thead tr th {
    font-weight: normal;
}